<template>
  <div class="row justify-content-center px-8 px-lg-10">
    <div class="col-xl-12 col-xxl-7 justify-content-center">
      <!--begin::Form Wizard-->
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <form class="form">
          <!--begin::Step 1-->
          <div data-wizard-type="step-content" data-wizard-state="current">
            <div class="row">
              <div class="col-xl-12">
                <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label"
                    >{{ $t('roles.name') }}</label
                  >
                  <validation-provider rules="required" v-slot="{ classes, errors }" class="col-lg-9 col-xl-9" v-if="!isLoading">
                    <input
                      class="form-control"
                      :class="classes"
                      :name="$t('roles.name')"
                      type="text"
                      v-model="roles.name"
                    />
                    <span class="error__message">{{ errors[0] }}</span>
                  </validation-provider>
                  <div v-else class="col-lg-9 col-xl-9">
                    <Skeleton height="38px" />
                    <span class="error__message"></span>
                  </div>
                </div>

                <div class="form-group row">
                  <template v-if="!isLoading">
                  <div
                    v-for="(per, key) in permissions"
                    :key="key"
                    class="col-12 mt-3 row"

                  >
                    <label class="col-12 row align-items-center checkbox">
                      <a href="javascript:void(0)" @click="expandPermission(key)" class="mr-2 hover-opacity-60">
                        <i class="fa fa-angle-right"></i>
                      </a>
                      <input
                        type="checkbox"
                        :checked="containsAll(per.items.map((val) => val.id))"
                        @change="
                          onChangePermission(per.items.map((val) => val.id))
                        "
                        :value="key"
                      />
                      <span></span>
                      <a
                        href="javascript:void(0)"
                        class="d-inline-block ml-2"
                        @click="expandPermission(key)"
                      >
                        {{ per.text }}</a
                      >
                    </label>
                    <template
                      v-if="expandedPermissions.some((val) => +val === +key)"
                    >
                      <div
                        class="col-xl-11 col-lg-11 offset-1 p-2"
                        v-for="perItem in per.items"
                        :key="perItem.id"
                      >
                        <label class="checkbox">
                          <input
                            type="checkbox"
                            :checked="
                              roles.permissions.some(
                                (val) => +val === +perItem.id
                              )
                            "
                            :value="perItem.id"
                            @change="onChangePermission([perItem.id])"
                          />
                          <span></span>
                          <div class="d-inline-block ml-2">
                            {{ perItem.text }}
                          </div>
                        </label>
                      </div>
                    </template>
                  </div>
                  </template>
                  <div v-else class="col-lg-9 col-xl-9">
                    <Skeleton height="20px" width="200px" style="margin-bottom: 10px;display: block"  v-for="(item, index) in 5" :key="'loading-' + index" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--begin::Actions-->
          <div class="d-flex justify-content-end border-top mt-5 pt-10">
            <form-submit-btn
              :btnClass="'btn btn-success font-weight-bolder text-uppercase px-9 py-4'"
              :isSubmitting="isSubmitting"
              @click="handleSubmit(createRoles)"
              :text="$t('commons.submit')"
            />
          </div>
          <!--end::Actions-->
        </form>
      </ValidationObserver>
      <!--end::Form Wizard-->
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { Skeleton } from 'vue-loading-skeleton';

export default {
  name: "NewRoles",
  data() {
    return {
      roles: {
        name: "",
        permissions: [],
      },
      expandedPermissions: [],
    };
  },
  components: {
    Skeleton
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.roles.isLoading,
      rolesGet: (state) => state.roles.roles_new_get,
      permissions: function () {
        return this.rolesGet.permissions;
      },
    }),
  },
  methods: {
    ...mapActions("roles", ["GET_NEW_ROLES", "POST_ROLES"]),
    createRoles() {
      this.POST_ROLES(this.roles);
    },
    onChangePermission(permissionIds) {
      /**
       * if clicked to select all and all of the permissions are selected then clear all
       */
      if (permissionIds.length > 1 && this.containsAll(permissionIds)) {
        permissionIds.forEach((perId) => {
          this.roles.permissions = this.roles.permissions.filter(
            (val) => +val !== +perId
          );
        });
        return;
      }
      /**
       * if clicked to select all and all and if selected one or more than one permission then select other permissions that are not selected
       */
      if (permissionIds.length > 1 && !this.containsAll(permissionIds)) {
        permissionIds.forEach((perId) => {
          this.roles.permissions.push(perId);
        });
        return;
      }

      // select or update if selected one by one permission
      permissionIds.forEach((permissionId) => {
        if (this.roles.permissions.some((val) => +val === +permissionId)) {
          this.roles.permissions = this.roles.permissions.filter(
            (val) => +val !== +permissionId
          );
        } else {
          this.roles.permissions.push(permissionId);
        }
      });
    },
    expandPermission(key) {
      if (this.expandedPermissions.some((val) => +val === +key)) {
        this.expandedPermissions = this.expandedPermissions.filter(
          (val) => +val !== +key
        );
        return;
      }
      this.expandedPermissions.push(key);
    },
    containsAll(items) {
      return items.every((i) => this.roles.permissions.includes(i));
    },
  },
  created() {
    this.GET_NEW_ROLES();
  },
};
</script>
